import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-message-status',
  templateUrl: './edit-message-status.component.html',
  styleUrls: ['./edit-message-status.component.scss']
})
export class EditMessageStatusComponent {

}
