<div class="wrapper">
    <app-header/>
    <app-intro/>
    <app-biography id="bio"/>
    <app-carousel/>
    <app-cards id="service"/>
    <app-location/>
    <app-form id="contact"/>
    <app-footer/>
</div>





