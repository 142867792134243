<app-header [usuario]="usuario"/>
<div class="row">
    <div class="wrapper">
        <div class="table-responsive">
            <h2>Attended Messages</h2>
            <table class="table table-striped my-custom-style d-justify-content-center align-items-center">
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>E-mail</th>
                    <th>Date</th>
                    <th>User</th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let message of messages, let i = index">
                        <td class="text-center">{{ i + 1}}</td>
                        <td class="text-center">{{ message.firstname | titlecase }} {{ message.lastname | titlecase }}</td>
                        <td class="text-center"> {{ message.phone | phone }} </td>
                        <td class="text-center"> {{ message.email }} </td>
                        <td class="text-center"> {{ message.fecha_mensaje | date }} </td>
                        <td class="text-center"> {{ message.username}}</td>
                        <td class="text-center"> <button type="button" (click)="getId( message.message_users?.message_users_id || -1)" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i class="pi pi-pencil"></i> Edit</button> </td>
                    </tr>
            </table>
    </div>
</div>
</div>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h1 class="modal-title fs-5" id="staticBackdropLabel">Edit Message Status:</h1>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <table>
        <tbody>
            <tr>
                <td>Name:</td>
                <td></td>
            </tr>
            <tr>
                <td>Phone:</td>
                <td></td>
            </tr>
            <tr>
                <td>E-mail:</td>
                <td></td>
            </tr>
            <tr>
                <td>fecha:</td>
                <td></td>
            </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
      <button type="button" class="btn btn-primary">Submit</button>
    </div>
  </div>
</div>
  

