<div class="biography">
  <div class="img-bio slide-right">
    <img class="img-person" src="../../../assets/images/doctor.png" alt="Imagen de la persona" style="object-fit: contain;">
  </div>
  <div class="text-bio">
    <div class="bio-card">
      <div class="card-body">
        <h5 class="card-title">Francisco Chumpitaz DDS</h5>
        <p class="card-text">Dr. Francisco Chumpitaz decided to become a dentist when he saw his father working with patients. Passionate about helping those in need, he realized dentistry was the right career for him. He received his DDS degree from Universidad Inca Garcilaso De la Vega in Peru and completed his Advanced Education in General Dentistry (AEGD) at Larkin Community Hospital. His favorite part of being a dentist is seeing a patient’s smile return after treatment.</p>
      </div>
    </div>
</div> 

    
    