<div id="carouselExampleControls" class="carousel slide bg-dark" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="carousel-item-overlay">
          <img src="../../../assets/images/sala.jpg" class="d-block w-100" alt="...">
          <div class="carousel-caption">
            <h5>Flor Lopez</h5>
            <p>"I have been a patient of Dr. Chumpitaz for 2 years. The service he provides is very professional,
              and the level of care for his patients is very good. I felt very confortable with him."
            </p>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="carousel-item-overlay">
          <img src="../../../assets/images/sala2.jpg" class="d-block w-100" alt="...">
          <div class="carousel-caption">
            <h5>Michael Green</h5>
            <p>“I have been seeing Dr. Chumpitaz for several years and I will continue to do so because of his quality of care
               and the continued concern toward seeing that his patients are well.”
            </p>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="carousel-item-overlay">
          <img src="../../../assets/images/working.jpg" class="d-block w-100" alt="...">
          <div class="carousel-caption">
            <h5>Kate Wright</h5>
            <p>“I have been seeing Dr. Chumpitaz for several years and I will continue to do so because of his quality of 
              care and the continued concern toward seeing that his patients are well.”
            </p>
          </div>
        </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
      <span class="visually-hidden">Next</span>
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
    </button>
  </div>
