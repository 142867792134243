<div class="intro">
  <div class="hero-content">
    <img src="../../../assets/images/fondo7.jpg" alt="intro">
    <div class="intro-texto">
      <p>JF Dental Care</p>
    </div>
  </div>
</div>

  
