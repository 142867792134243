<div class="navbar navbar-not-sticky" [class.navbar-sticky]="sticky" [class.sticky-header]="sticky">
  <div class="logo">
    <h1>JF Dental Care</h1> 
  </div>
  <app-hamburguesa></app-hamburguesa>
  <div class="options">
    <nav>
      <ul>
        <li><a href="/" routerLinkActive="active">Home</a></li>
        <li><a href="#bio" routerLinkActive="active">About the doctor</a></li>
        <li><a href="#service" routerLinkActive="active">Our Service</a></li>
        <li><a href="#contact" routerLinkActive="active">Book an appointment</a></li>
        <li><a routerLink="/auth" routerLinkActive="active">Login</a></li>
      </ul>
    </nav>
  </div>
</div>


<!-- [ngClass]="{ 'show-menu': menuOpen, 'hide-menu': !menuOpen}" -->