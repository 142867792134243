
    <app-header [usuario]="usuario"></app-header>

    <div class="row">
      <div class="col-9 mx-auto">
          <h2>Messages:</h2>
          <table class="table table-striped my-custom-style d-justify-content-center align-items-center">
            <thead class="thead">
              <tr>
                <th scope="order" class="table-primary text-center">#</th>
                <th scope="name" class="table-primary text-center">Name</th>
                <th scope="phone" class="table-primary text-center">Phone</th>
                <th scope="email" class="table-primary text-center">E-mail</th>
                <th scope="fecha" class="table-primary text-center">Date</th>
                <th class="table-primary"></th>
              </tr>
            </thead>
            <tbody>
              <tr class="wrap" *ngFor="let message of messages, let i = index">
                <td class="text-center">{{ i + 1 }}</td>
                <td class="text-center">{{ message.firstname | titlecase }} {{ message.lastname | titlecase }}</td>
                <td class="text-center">{{ message.phone | phone }}</td>
                <td class="text-center">{{ message.email | lowercase }}</td>
                <td class="text-center">{{ message.fecha_mensaje | date}}</td>
                <td class="text-center"> 
                  <button type="button" class="btn btn-secondary" style="padding:3px 14px 3px 14px" (click)="getId(message.message_id)">Info</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>