<div class="wrapper">
    <div class="footer">
        <div class="contact">
            <h4 class="mb-3">JF Dental Care</h4>
            <p><i class="pi pi-phone"></i> 407-703-3311</p>
            <div class="address">
                <p><i class="pi pi-map-marker"></i> 380 B Semoran Commerce Place, Suite 204 </p>
                <p> Apopka Fl, 32703 </p>
                <hr>
                <a href="https://www.facebook.com/jfdentalcare1" target="_blank"><i class="pi pi-facebook"></i></a>
                <a href="https://www.instagram.com/jf.dentalcare/" target="_blank"><i class="pi pi-instagram"></i></a>
            </div>
        </div>
        <div class="hours">
            <h5> Our Hours</h5>
            <p><i class="pi pi-clock"></i>&nbsp; Monday: 1:00PM - 8:00PM</p>
            <p><i class="pi pi-clock"></i>&nbsp; Tuesday: 1:00PM - 8:00PM</p>
            <p><i class="pi pi-clock"></i>&nbsp; Wednesday: 1:00PM - 8:00PM</p>
            <p><i class="pi pi-clock"></i>&nbsp; Thursday: 1:00PM - 8:00PM</p>
            <p><i class="pi pi-clock"></i>&nbsp; Friday: 9:00AM - 5:00PM</p>
            <p><i class="pi pi-clock"></i>&nbsp; Saturday: 9:00AM - 5:00PM</p>
            <p><i class="pi pi-clock"></i>&nbsp; Sanday: Closed</p>
        </div>
        <div class="services">
            <h5>Explore Our Service</h5>
            <p><i class="pi pi-check"></i> Dental Emergencies</p>
            <p><i class="pi pi-check"></i> General Dentistry</p>
            <p><i class="pi pi-check"></i> Invisalign</p>

        </div>
    </div>
</div>



