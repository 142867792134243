<div class="wrapper">
    <div class="text-location">
        <div class="texto">
            <h2>Visit a Dental Team That Cares About You</h2>
            <p>At JF Dental Care, we welcome patients from all walks of life.
                You'll never feel judged when you visit us for your dental needs,
                whether you just need a cleaning or want to secure your dentures with
                implants. We're a family-friendly practice and encourage you to bring 
                your loved ones in for a visit. We have a relaxing atmosphere and treat
                you with the respect you deserve. ¿Habla usted español? Nosotros también!</p>
            <p>
                As your dentist in Apopka, we look forward to rebuilding and strengthening
                your dental health. Since your smile is one of the first things people notice
                about you, you'll want it to be an example of beauty and health. Our team is
                passionate about the services we offer, and we love seeing patients leave our 
                practice feeling better than when they walked in. We want to become your partner 
                in dental care throughout your life! Call 407-703-3399 for an appointment, or you
                can also schedule an appointment online.
            </p> 
        </div>
    </div>
    <div class="img-location">
        <div class="imagen-container">
            <img src="../../../assets/images/img.jpg" alt="sala">
        </div>
    </div>
</div>

